// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledOuterContainer = styled("div")`
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	margin-top: 0;
	position: relative;
	z-index: 2;
	${({ theme: { colors, utils, isDarkMode } }) => css`
		background-color: ${isDarkMode
			? colors.white
			: utils.lighten(colors.white, 2)};
	`}
`;

// #####################################################
